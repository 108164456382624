import React from "react"
import { SectionTitle } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor } from "../../site-config"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledP = styled.p`
  max-width: 720px;
  margin: auto;
  padding: 1rem;
`
const ThankYou = () => (
  <Layout>
    <SEO title="Thank you" href="https://mopodiatry.com.au/thank-you" />
    <SectionTitle text="Thanks" color={primaryColor} level="h1" size="4rem" />
    <StyledP>We'll be in touch soon.</StyledP>
  </Layout>
)

export default ThankYou
